<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" :content="content"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')" v-if="prescriptionType == 2">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules" style="width: 80%">
            <el-form-item label="处方名称:" prop="prescriptionName">
              <el-input v-model="form.prescriptionName" :disabled="prescriptionType != 2"></el-input>
            </el-form-item>
            <el-form-item label="药品:" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
              <div class="my-table">
                <el-table :data="form.mzPrescriptionDetail">
                  <el-table-column label="药品名称" width="260">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].drugOrgId'" :rules="rules.drugOrgId">
                        <el-select class="" size="small" v-model="scope.row.drugOrgId" filterable remote clearable placeholder="请输入关键词" :remote-method="remoteMethod" :loading="isLoading" @change="handleSelect($event, scope.row)" :disabled="prescriptionType != 2">
                          <el-option v-for="item in options" :key="item.id" :label="`${item.mzDrug.drugName}${item.mzDrug.drugType == '' ? '' : item.mzDrug.drugType == 'T' ? '(统)' : item.mzDrug.drugType == 'X' ? '(选)' : '(精选)'}(${item.specification}/${item.specificationUnit}${item.productFactory ? ' - ' + item.productFactory : ''})`" :value="item.id"> </el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="groupNumber" label="组号">
                    <template slot-scope="scope">
                      <el-form-item label="">
                        <el-input v-model="scope.row.groupNumber" size="small" placeholder="组号" :disabled="prescriptionType != 2"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="usage" label="用法" width="120">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].usage'" :rules="rules.usage">
                        <!-- <el-input v-model="scope.row.usage" size="small" placeholder="用法"></el-input> -->
                        <el-select v-model="scope.row.usage" size="small" placeholder="用法" class="w-100" clearable :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in usage"></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="frequency" label="用药频率" width="150">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].frequency'" :rules="rules.frequency">
                        <el-select v-model="scope.row.frequency" size="small" placeholder="用药频率" clearable :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in frequency"></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosage" label="单次剂量" width="120">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].dosage'" :rules="rules.dosage">
                        <!-- <el-input v-if="scope.row.edit" v-model="scope.row.dosage" name="dosage" size="small"
                          placeholder="单次剂量"></el-input> -->
                        <el-input-number controls-position="right" v-if="scope.row.edit" size="small" v-model="scope.row.dosage" :precision="2" :step="1" :disabled="prescriptionType != 2"></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosageUnit" label="剂量单位" width="100">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].dosageUnit'" :rules="rules.dosageUnit">
                        <el-select v-if="scope.row.edit" size="small" v-model="scope.row.dosageUnit" placeholder="单位" :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="defaultDosage" label="开药量" width="120">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].defaultDosage'" :rules="rules.defaultDosage">
                        <!-- <el-input type="number" v-if="scope.row.edit" v-model="scope.row.defaultDosage" size="small"
                          placeholder="开药量"></el-input> -->
                        <el-input-number controls-position="right" v-if="scope.row.edit" size="small" v-model="scope.row.defaultDosage" :precision="2" :step="1" :disabled="prescriptionType != 2"></el-input-number>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column prop="defaultDosageUnit" label="开药量单位" width="100">
                    <template slot-scope="scope">
                      <el-form-item label="" :prop="'mzPrescriptionDetail[' + scope.$index + '].defaultDosageUnit'" :rules="rules.defaultDosageUnit">
                        <el-select v-if="scope.row.edit" size="small" v-model="scope.row.defaultDosageUnit" placeholder="单位" disabled>
                          <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="tradingPrice" label="价格"></el-table-column> -->
                  <el-table-column align="center" width="100px" v-if="prescriptionType == 2">
                    <template slot-scope="scope">
                      <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                        <i class="el-icon-delete" aria-hidden="true"></i>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="padding: 0 10px">
                  <span class="primary optionBtn" @click="handleAdd()" v-if="prescriptionType == 2">添加药品</span>
                  <span class="warning optionBtn" @click="handleReset()" v-if="prescriptionType == 2">重置</span>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      currentIndex: 0,
      id: this.$route.query.id,
      form: {
        prescriptionName: '',
        type: 1, //0成药（饮片）处方 1 成药处方 2 输液处方
        mzPrescriptionDetail: [
          {
            edit: true,
            drugOrgId: '',
            drugOrgName: '',
            groupNumber: null,
            usage: null,
            frequency: null,
            dosage: undefined,
            dosageUnit: null,
            defaultDosage: undefined,
            defaultDosageUnit: null,
            tradingPrice: null,
          },
        ],
      },
      isLoading: false,
      options: [],
      dosageUnit: ['片', '粒', '支', '袋', '枚', '瓶', '滴', '适量', 'ug', 'mg', 'g', 'ul', 'ml', 'IU'],
      usage: ['口服', '外用', '滴眼', '静脉注射', '静脉滴注', '肌内注射', '皮下注射', '皮内注射', '腔内注射', '椎管注射', '动脉注射', '心内注射', '球内注射', '皮下埋植', '口腔喷雾', '口腔吸入', '口腔黏膜给药', '滴鼻', '鼻腔喷雾', '鼻饲', '鼻腔吸入', '滴耳', '眼科外用', '涂于眼睑内', '含漱', '含服', '舌下含服', '直肠给药', '直肠塞入', '肛门涂抹', '阴道给药', '阴道塞入', '阴道擦洗'],
      frequency: ['1日1次(qd)', '1日2次(bid)', '1日3次(tid)', '隔日1次(qod)', '必要时(prn)', '1日4次(qid)', '1周1次(qwd)', '隔周1次(qow)', '隔天1次(qod)', '每晚1次(qn)', '立即(st)'],
      remark: ['先煎', '后下', '包煎', '另煎', '烊化', '冲服', '捣碎'],
      rules: {
        prescriptionName: [{ required: true, message: '请输入处方名称', trigger: 'blur' }],
        drugOrgId: [{ required: true, message: '请输入药品名称', trigger: 'blur' }],
        groupNumber: [{ required: true, message: '请输入组号', trigger: 'blur' }],
        usage: [{ required: true, message: '请选择用法', trigger: 'change' }],
        frequency: [{ required: true, message: '请选择用药频率', trigger: 'change' }],
        dosage: [{ required: true, message: '请输入单次剂量', trigger: 'blur' }],
        dosageUnit: [{ required: true, message: '请选择剂量单位', trigger: 'change' }],
        defaultDosage: [{ required: true, message: '请输入开药量', trigger: 'blur' }],
        defaultDosageUnit: [{ required: true, message: '请选择开药量单位', trigger: 'change' }],
      },
      prescriptionType: 2, //1个人 2机构 3总后台
      content: '',
    }
  },
  mounted() {
    if (this.id) {
      this.getPrescriptionInfo()
    } else {
      this.content = '新增成药处方'
    }
  },
  methods: {
    getPrescriptionInfo() {
      var _this = this
      _this.OutpatientDomain.PrecriptionDetail(
        _this.id,
        function (res) {
          _this.prescriptionType = res.data.prescriptionType
          if (_this.prescriptionType == 1) {
            _this.content = '成药处方详情'
          } else {
            _this.content = '编辑成药处方'
          }
          _this.form.id = res.data.id
          _this.form.prescriptionName = res.data.prescriptionName
          _this.form.type = res.data.type
          _this.form.mzPrescriptionDetail = []
          res.data.mzPrescriptionDetail.forEach((item) => {
            let row = {
              edit: true,
              id: item.id,
              drugOrgId: item.drugOrgId,
              drugOrgName: item.drugOrgName,
              groupNumber: item.groupNumber,
              usage: item.usage,
              frequency: item.frequency,
              dosage: item.dosage,
              dosageUnit: item.dosageUnit,
              defaultDosage: item.defaultDosage,
              defaultDosageUnit: item.defaultDosageUnit,
              tradingPrice: item.tradingPrice,
            }
            _this.form.mzPrescriptionDetail.push(row)
          })
        },
        function (error) {
          console.log(error)
        }
      )
    },
    async querySearchAsync(queryString, cb) {
      var results = await this.getSearchRes(queryString)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 3000 * Math.random())
    },
    getSearchRes(queryString) {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.OutpatientDomain.DrugByOrgIn(
          queryString,
          function (data) {
            data.data.forEach((item) => {
              _this.$set(item, 'value', item.drugName)
            })
            resolve(data.data)
          },
          function (err) {
            console.log(err)
            resolve([])
          }
        )
      })
    },
    remoteMethod(queryString) {
      var _this = this
      if (queryString !== '') {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          _this.OutpatientDomain.DrugByOrgIn(
            queryString,
            function (res) {
              _this.options = res.data
            },
            function (error) {
              console.log('药品列表请求失败!请刷新重试:', error)
            }
          )
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect(e, map) {
      let item = this.options.filter((i) => i.id == e)[0]
      if (item) {
        map.drugOrgName = item.mzDrug.drugName
        map.tradingPrice = item.tradingPrice
        map.usage = item.usage
        map.frequency = item.frequency
        map.dosage = item.dosage
        map.dosageUnit = item.dosageUnit
        map.defaultDosage = item.defaultDosage
        map.defaultDosageUnit = item.defaultDosageUnit
      }
    },
    saveData(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.form.id) {
            _this.OutpatientDomain.EditMZPrecription(
              _this.form,
              function (data) {
                _this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              }
            )
          } else {
            _this.OutpatientDomain.AddMZPrecription(
              _this.form,
              function (data) {
                _this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleReset() {
      let row = {
        edit: true,
        drugOrgId: '',
        drugOrgName: '',
        groupNumber: null,
        usage: null,
        frequency: null,
        dosage: undefined,
        dosageUnit: null,
        defaultDosage: undefined,
        defaultDosageUnit: null,
        tradingPrice: null,
      }
      this.form.mzPrescriptionDetail = []
      this.form.mzPrescriptionDetail.push(row)
    },
    handleAdd() {
      let row = {
        edit: true,
        drugOrgId: '',
        drugOrgName: '',
        groupNumber: null,
        usage: null,
        frequency: null,
        dosage: undefined,
        dosageUnit: null,
        defaultDosage: undefined,
        defaultDosageUnit: null,
        tradingPrice: null,
      }
      this.options = []
      this.form.mzPrescriptionDetail.push(row)
    },
    handleDelete(row, index) {
      if (this.form.mzPrescriptionDetail.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.mzPrescriptionDetail.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;
    height: calc(100% - 30px - 73px);
    overflow-y: auto;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    ::v-deep .el-input-number--small {
      width: auto !important;
    }
  }
}
</style>
